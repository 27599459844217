(function () {

    const grids = document.querySelectorAll('.bloc-mosaique__inner');
    grids.forEach(grid => {
        const colcade = new Colcade(grid, {
            columns: '.grid-col',
            items: '.grid-item'
        });
    });

})();