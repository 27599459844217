(function () {

    var sliders = document.getElementsByClassName('carousel-thematique');

    for (var i = 0; i < sliders.length; i++) {
        new Splide(sliders[i], {
            pagination: false,
            autoplay: false,
            easing: "ease",
            autoWidth: false,
            focus: 0,
            omitEnd: true,
        }).mount();
    }
})();