(function () {

    var sliders = document.getElementsByClassName('slider-horaires');


    for (var i = 0; i < sliders.length; i++) {
        var getArrowsAttr = (sliders[i].getAttribute("data-has-arrows") === 'true');
        let start = 1;
        let active_slide = $(".active", sliders[i]);
        if (active_slide) {
            start = active_slide.data('index');
        }

        new Splide(sliders[i], {
            arrows: getArrowsAttr,
            pagination: false,
            autoplay: false,
            easing: "ease",
            lazyLoad: 'nearby',
            autoWidth: true,
            focus: 0,
            start: start,
            omitEnd: true,
        }).mount();
    }
})();