//spécificités listing Agenda
parseCardAgendaClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topleft');
}


// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topright');
}


// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors:{
        mapoverlay:'#map-listing',
        map: '#map-listing-sit',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: true,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        // $('.facette-radio, .facette-checkbox label, .facette-switch').append('<span></span>');
    }
});

// listing SIT FMA - uniquement une map en overlay + overlay sur les cards
var listingSitFMA = new thListingManager({
    selectors:{
        listing: '.js-listing-fma',
        mapoverlay:'#map-listing',
    },
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        parseCardAgendaClickOverlay();
    }
});

// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors:{
        listing: '.js-listing-cpt',
    },
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if(listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if(listingSitFMA.loaded) {
                listingSitFMA.initMapOverlay();
            }
        }, 250);
    }
});
