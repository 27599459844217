if ($(".js-listing-expressions-mosaic").length > 0) {
    var masonry = new MiniMasonry({
        container: '.js-listing-expressions-mosaic',
        gutter: 35,
        surroundingGutter: false,
        minify: true
    });

    if (isMobileOrSmaller()) {
        masonry.destroy();
    }
}



