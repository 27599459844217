(function () {

    var slider = document.getElementById('cover-home');

    if (slider) {
        document.addEventListener('DOMContentLoaded', function () {
            var splide = new Splide(slider, {
               type: 'loop',
                pagination: false,
                pauseOnHover: false,
                autoplay: true,
                interval: 4500,
                easing: "ease",
                lazyLoad: 'nearby',
                autoWidth: true,
            });
            splide.mount();
        });
    }
})();