var carteInteractive = {
    selector: {
        form: "#carte_interactive_facettes",
        map: "#carte_interactive_map",
        btnCloseFiltres: "#close_filtres_carte",
        btnOpenFiltres: "#open_filtres_carte"
    },

    map: null,
    markers: [],

    init: function () {
        if(document.querySelector(carteInteractive.selector.map)) {
            carteInteractive.loadMap();
        }
    },

    loadMap: function () {
        carteInteractive.map = th_maps.initSingleMap(document.querySelector(carteInteractive.selector.map));

        $.ajax({
            url: "/wp-admin/admin-ajax.php",
            method: "get",
            data: {
                action: "get_carte_interactive",
                post_id: $(carteInteractive.selector.form).data('post_id')
            }
        })
            .then(function (response) {
                var res = JSON.parse(response);

                carteInteractive.loadPoints(carteInteractive.map, res.data.points);
                carteInteractive.appendHTML(carteInteractive.selector.form, res.data.HTMLInputs);

                carteInteractive.initDropdowns();
                carteInteractive.inputEvents();
                carteInteractive.openByDefault();
            });
    },

    loadPoints: function (map, json) {
        $.each(json, function () {
            var selection = this.selection_id;
            var icon = null;
            var color = this.color;

            if (this.icon) {
                icon = "icon-" + selection;

                console.log("icon",icon);

                th_maps.addMarkerIcon(icon, {
                    className: icon,
                    html: '<div class="marker-pin-actif"><svg class="' + this.color + '" xmlns="http://www.w3.org/2000/svg" width="32.156" height="42.019" viewBox="0 0 32.156 42.019">\n' +
                        '  <path id="Icon_ionic-md-pin" data-name="Icon ionic-md-pin" d="M22.828,2.25A15.966,15.966,0,0,0,6.75,18.007c0,11.817,16.078,26.262,16.078,26.262S38.906,29.824,38.906,18.007A15.966,15.966,0,0,0,22.828,2.25Z" transform="translate(-6.75 -2.25)" fill="#ed336e"/>\n' +
                        '</svg><span class="' + this.icon + '"></span></div>',
                    shadowUrl: null,
                    iconSize: [32, 42],
                    iconAnchor: [16, 21]
                });
            }

            $.each(this.points, function () {

                var marker = th_maps.createMarker(null, {
                    lat: this.lat,
                    lng: this.lng
                }, icon ? icon : 'default', 'selection-' + selection);
                var lat = this.lat;
                var lng = this.lng;
                var post_id = this.id;

                carteInteractive.markers.push(marker);

                marker.on('click', function () {
                    if (carteInteractive.lastActiveMarker) {
                        // if (icon) {
                        //     carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                        // } else {
                        //     carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                        // }
                        carteInteractive.lastActiveMarker = null;
                    }
                    // marker.setIcon(th_maps.markersIcons['hover']);
                    carteInteractive.lastActiveMarker = marker;

                    $.ajax({
                        url: "/wp-admin/admin-ajax.php",
                        method: "POST",
                        data: {
                            action: "get_carte_interactive_point",
                            post_id: post_id
                        },
                        success: function (html) {
                            var response = JSON.parse(html);
                            var content = response.html;

                            var infoWindow = th_maps.createInfoWindow(content);
                            var containerClass = infoWindow.options.className;

                            infoWindow.options.className = containerClass + " " + color;
                            infoWindow.on('remove', function () {
                                if (icon) {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                                } else {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                                }
                            });
                            infoWindow.setLatLng({lat: lat, lng: lng}).openOn(map);

                            var lazyLoadInstance = new LazyLoad({
                                elements_selector: ".lazy"
                            });
                        }
                    });
                })

            })
        });

        var group = new L.featureGroup(carteInteractive.markers);
        carteInteractive.map.fitBounds(group.getBounds(), {padding: [50, 50]});

        $(carteInteractive.selector.form).on('submit', function (e) {
            e.preventDefault();
            th_overlay.close('overlay-filtres-carte');
        });
    },

    appendHTML: function (container, content) {
        if (container && content) {
            document.querySelector(container).innerHTML = content;
        }
    },

    initDropdowns: function () {
        // accordeon des filtres
        $('.group-checkbox > legend', carteInteractive.selector.form).on("click", function () {
            if (this.parentNode.classList.contains("is-active")) {
                this.parentNode.classList.remove("is-active");
            } else {
                this.parentNode.classList.add("is-active");
            }
        });

        $('.frm_checkbox > label', carteInteractive.selector.form).append('<span></span>');
    },

    inputEvents: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("change", function () {
            var el = this;
            setTimeout(function () {
                if (el.checked) {
                    th_maps.showGroupMarker('selection-' + el.value, carteInteractive.map);
                } else {
                    th_maps.hideGroupMarker('selection-' + el.value);
                }
            }, 50);
        });

        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("force-change", function () {
            var el = this;
            setTimeout(function () {
                if (el.checked) {
                    th_maps.showGroupMarker('selection-' + el.value, carteInteractive.map);
                } else {
                    th_maps.hideGroupMarker('selection-' + el.value);
                }
            }, 50);
        });
    },

    openByDefault: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).each(function () {
            if (this.getAttribute("data-display-default")) {
                $(this).click();
            }
        });
    }
};

carteInteractive.init();