var aPropos = $(".sit-a-propos .inner-content");
var aProposHeight = aPropos.height();
var aProposMaxHeight = getComputedStyle(document.body).getPropertyValue('--a-propos-max-height');
var aProposMaxHeightFormat = aProposMaxHeight.replace('px', '');
var btnViewMore = $(".single-sit .btn-view-more");
if (aProposHeight > aProposMaxHeightFormat) {
    aPropos.addClass("hide-content");
}
btnViewMore.on("click", function () {
    var parentClass = btnViewMore.parents(".inner-content");
    parentClass.removeClass("hide-content");
    if (parentClass.hasClass("show-everything")) {
        console.log("test");
        parentClass.removeClass("show-everything");
        parentClass.addClass("hide-content");
    } else {
        parentClass.addClass("show-everything");
    }


});